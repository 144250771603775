<template>
  <v-form
    ref="form"
    class="multi-col-validation"
    method="post"
    action="/"
    lazy-validation
    @submit.prevent="saveSettings()"
  >
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <h1 class="text-center">
        System settings for {{ station_name }}
      </h1>
      <v-select
        v-model="station_id"
        mt-50
        :items="stations"
        item-text="name"
        item-value="id"
        label="Select station"
        :loading="isLoading"
        class="mb-6"
        @change="selectedStationChange"
      ></v-select>
      <v-card-text class="pt-5">
        <h2 class="font-weight-medium">
          Prepaid meters
        </h2>
      </v-card-text>
      <v-card-text class="pt-1">
        <v-label>Charges</v-label>
        <v-row class="mt-1">
          <v-col cols="2">
            <v-text-field
              v-model="form.prepaid_cost_per_unit"
              label="Cost per unit (in Ksh)"
              placeholder="130"
              dense
              outlined
              :rules="numberRules"
              :loading="isLoading"
              :disabled="isLoading"
              number
            ></v-text-field>
            <small
              v-show="form.errors.has('prepaid_cost_per_unit')"
              class="validation-error"
            >{{
              form.errors.get('prepaid_cost_per_unit')
            }}</small>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="can('service-charge-edit')">
        <v-label>Service charge</v-label>
        <v-row class="mt-1">
          <v-col cols="3">
            <v-select
              v-model="form.prepaid_service_charge_in"
              label="Service charge in"
              class="md-1"
              :items="serviceChargeOptions"
              item-text="name"
              item-value="value"
              outlined
              dense
              :loading="isLoading"
              :disabled="isLoading"
              @change="prepaid_service_chargeOptionChange()"
            ></v-select>
            <small
              v-show="form.errors.has('prepaid_service_charge_in')"
              class="validation-error"
            >{{
              form.errors.get('prepaid_service_charge_in')
            }}</small>
          </v-col>
        </v-row>
        <v-row
          v-for="(service_charge, index) in form.prepaid_service_charge"
          :key="index"
          class="mt-1"
        >
          <v-col md="2">
            <v-text-field
              v-model="service_charge.from"
              outlined
              dense
              label="From (Ksh)"
              placeholder="1"
              :rules="numberRules"
              :loading="isLoading"
              :disabled="isLoading"
              required
            />
          </v-col>
          <v-col>
            <p class="text-center mt-2">
              TO
            </p>
          </v-col>
          <v-col md="2">
            <v-text-field
              v-model="service_charge.to"
              outlined
              dense
              label="To (Ksh)"
              placeholder="100"
              :rules="numberRules"
              :loading="isLoading"
              :disabled="isLoading"
              required
            />
          </v-col>
          <v-col>
            <p
              class="text-center mt-1"
              style="font-size: 18px"
            >
              =
            </p>
          </v-col>
          <v-col md="3">
            <v-text-field
              v-model="service_charge.amount"
              :label="prepaid_service_chargeLabel"
              placeholder="130"
              dense
              outlined
              :rules="numberRulesAllowZero"
              :loading="isLoading"
              :disabled="isLoading"
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <v-btn
              v-show="index === 0"
              class="ml-4"
              small
              outlined
              fab
              color="primary"
              @click="addPrepaidServiceChargeInput"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
            </v-btn>
            <v-btn
              v-show="index != 0"
              class="ml-4"
              small
              outlined
              fab
              color="primary"
              @click="removePrepaidServiceChargeInput(index)"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <small
          v-show="form.errors.has('prepaid_service_charge')"
          class="validation-error"
        >{{
          form.errors.get('prepaid_service_charge')
        }}</small>
      </v-card-text><br />

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <h2 class="font-weight-medium">
          Post-paid meters
        </h2>
      </v-card-text>
      <v-card-text class="pt-1">
        <v-label>Charges</v-label>
        <v-row class="mt-1">
          <v-col cols="2">
            <v-text-field
              v-model="form.postpaid_cost_per_unit"
              label="Cost per unit (in Ksh)"
              placeholder="130"
              dense
              outlined
              :rules="numberRules"
              :loading="isLoading"
              :disabled="isLoading"
              number
            ></v-text-field>
            <small
              v-show="form.errors.has('prepaid_cost_per_unit')"
              class="validation-error"
            >{{
              form.errors.get('prepaid_cost_per_unit')
            }}</small>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="can('service-charge-edit')">
        <v-label>Service charge</v-label>
        <v-row class="mt-1">
          <v-col cols="3">
            <v-select
              v-model="form.postpaid_service_charge_in"
              label="Service charge in"
              class="md-1"
              :items="serviceChargeOptions"
              item-text="name"
              item-value="value"
              outlined
              dense
              :loading="isLoading"
              :disabled="isLoading"
              @change="postpaid_service_chargeOptionChange()"
            ></v-select>
            <small
              v-show="form.errors.has('postpaid_service_charge_in')"
              class="validation-error"
            >{{
              form.errors.get('postpaid_service_charge_in')
            }}</small>
          </v-col>
        </v-row>
        <v-row
          v-for="(service_charge, index) in form.postpaid_service_charge"
          :key="index"
          class="mt-1"
        >
          <v-col md="2">
            <v-text-field
              v-model="service_charge.from"
              outlined
              dense
              label="From (Ksh)"
              placeholder="1"
              :rules="numberRules"
              :loading="isLoading"
              :disabled="isLoading"
              required
            />
          </v-col>
          <v-col>
            <p class="text-center mt-2">
              TO
            </p>
          </v-col>
          <v-col md="2">
            <v-text-field
              v-model="service_charge.to"
              outlined
              dense
              label="To (Ksh)"
              placeholder="100"
              :rules="numberRules"
              :loading="isLoading"
              :disabled="isLoading"
              required
            />
          </v-col>
          <v-col>
            <p
              class="text-center mt-1"
              style="font-size: 18px"
            >
              =
            </p>
          </v-col>
          <v-col md="3">
            <v-text-field
              v-model="service_charge.amount"
              :label="postpaid_service_chargeLabel"
              placeholder="130"
              dense
              outlined
              :rules="numberRulesAllowZero"
              :loading="isLoading"
              :disabled="isLoading"
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <v-btn
              v-show="index === 0"
              class="ml-4"
              small
              outlined
              fab
              color="primary"
              @click="addPostpaidServiceChargeInput"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
            </v-btn>
            <v-btn
              v-show="index != 0"
              class="ml-4"
              small
              outlined
              fab
              color="primary"
              @click="removePostpaidServiceChargeInput(index)"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <small
          v-show="form.errors.has('prepaid_service_charge')"
          class="validation-error"
        >{{
          form.errors.get('prepaid_service_charge')
        }}</small>
      </v-card-text>
      <br />
      <v-card-text>
        <v-label>Meter reading message</v-label>
        <v-radio-group
          v-model="form.delay_meter_reading_sms"
          column
        >
          <v-radio
            label="Send meter reading message immediately after readings are added"
            value="0"
          ></v-radio>
          <v-radio
            label="Delay meter reading message for specified number of days after readings are added (recommended)"
            value="1"
          ></v-radio>
        </v-radio-group>
        <v-expand-transition>
          <v-row
            v-show="form.delay_meter_reading_sms == 1"
            class="ml-8"
          >
            <p class="text--primary body-1 mt-5">
              Send meter readings message to customers
            </p>
            <v-text-field
              v-model="form.meter_reading_sms_delay_days"
              placeholder="2"
              dense
              outlined
              :rules="meterReadingDateRules"
              :loading="isLoading"
              :disabled="isLoading"
              suffix="days"
              class="shrink number-input-3 ma-3"
            ></v-text-field>
            <p class="text--primary body-1 mt-5">
              after meter readings have been added
            </p>
            <small
              v-show="form.errors.has('meter_reading_sms_delay_days')"
              class="validation-error"
            >{{
              form.errors.get('meter_reading_sms_delay_days')
            }}</small>
          </v-row>
        </v-expand-transition>
      </v-card-text>
      <br />
      <v-card-text>
        <v-label>Bills</v-label>
        <v-row class="ml-4 mt-1">
          <p class="text--primary body-1 mt-5">
            Meter reading on
          </p>
          <v-text-field
            v-model="form.meter_reading_on"
            placeholder="25"
            dense
            outlined
            :rules="meterReadingDateRules"
            :loading="isLoading"
            suffix="th"
            :disabled="isLoading"
            :max="28"
            class="shrink number-input-2 ma-3"
          ></v-text-field>
        </v-row>
        <small
          v-show="form.errors.has('meter_reading_on')"
          class="validation-error"
        >{{
          form.errors.get('meter_reading_on')
        }}</small>
        <v-row class="ml-4 mt-1">
          <p class="text--primary body-1 mt-5">
            Bill due on
          </p>
          <v-text-field
            v-model="form.bill_due_on"
            placeholder="5"
            dense
            outlined
            suffix="th"
            :rules="numberRules"
            :loading="isLoading"
            :disabled="isLoading"
            class="shrink number-input ma-3"
          ></v-text-field>
          <small
            v-show="form.errors.has('bill_due_on')"
            class="validation-error"
          >{{
            form.errors.get('bill_due_on')
          }}</small>
        </v-row>
        <v-row class="ml-4 mt-1">
          <p class="text--primary body-1 mt-5">
            Tell customer meter disconnection is on
          </p>
          <v-text-field
            v-model="form.tell_user_meter_disconnection_on"
            placeholder="5"
            dense
            outlined
            suffix="th"
            :rules="numberRules"
            :loading="isLoading"
            :disabled="isLoading"
            class="shrink number-input ma-3"
          ></v-text-field>
          <small
            v-show="form.errors.has('tell_user_meter_disconnection_on')"
            class="validation-error"
          >{{
            form.errors.get('tell_user_meter_disconnection_on')
          }}</small>
        </v-row>
        <v-row class="ml-4 mt-1">
          <p class="text--primary body-1 mt-5">
            Actual meter disconnection on
          </p>
          <v-text-field
            v-model="form.actual_meter_disconnection_on"
            placeholder="8"
            dense
            outlined
            suffix="th"
            :rules="numberRules"
            :loading="isLoading"
            :disabled="isLoading"
            class="shrink number-input-2 ma-3"
          ></v-text-field>
          <small
            v-show="form.errors.has('actual_meter_disconnection_on')"
            class="validation-error"
          >{{
            form.errors.get('actual_meter_disconnection_on')
          }}</small>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <h2 class="font-weight-medium">
          Connection fee
        </h2>
      </v-card-text>
      <v-card-text>
        <v-label>Connection fee bill remainder message</v-label>
        <v-radio-group
          v-model="form.send_connection_fee_bill_remainder_sms"
          column
        >
          <v-radio
            label="Do not send connection fee bill remainder message"
            value="0"
          ></v-radio>
          <v-radio
            label="Send connection fee bill remainder message days before bill due date"
            value="1"
          ></v-radio>
        </v-radio-group>
        <v-expand-transition>
          <v-row
            v-show="form.send_connection_fee_bill_remainder_sms == 1"
            class="ml-8"
          >
            <p class="text--primary body-1 mt-5">
              Send connection fee bill remainder message to customers
            </p>
            <v-text-field
              v-model="form.days_before_sending_connection_fee_bill_remainder_sms"
              placeholder="5"
              dense
              outlined
              :rules="meterReadingDateRules"
              :loading="isLoading"
              :disabled="isLoading"
              suffix="days"
              class="shrink number-input-3 ma-3"
            ></v-text-field>
            <p class="text--primary body-1 mt-5">
              before bill due date
            </p>
            <small
              v-show="form.errors.has('days_before_sending_connection_fee_bill_remainder_sms')"
              class="validation-error"
            >{{
              form.errors.get('days_before_sending_connection_fee_bill_remainder_sms')
            }}</small>
          </v-row>
        </v-expand-transition>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <h2 class="font-weight-medium">
          Payments
        </h2>
      </v-card-text>
      <v-card-text>
        <v-row class="ml-4 mt-1">
          <p class="text--primary body-1 mt-5">
            Notify new payments on
          </p>
          <v-text-field
            v-model="form.payment_notification_phone_number"
            label="Phone number"
            placeholder="07*********"
            dense
            outlined
            :rules="optionalPhoneRules"
            :loading="isLoading"
            :disabled="isLoading"
            :max="15"
            class="shrink ma-3"
          ></v-text-field>
        </v-row>
        <small
          v-show="form.errors.has('payment_notification_phone_number')"
          class="validation-error"
        >{{
          form.errors.get('payment_notification_phone_number')
        }}</small>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <v-btn
          color="primary"
          class="me-3 mt-3"
          type="submit"
          :loading="form.busy"
        >
          Save changes
        </v-btn>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import axios from 'axios'
import Form from 'vform'
import { mapGetters, mapActions } from 'vuex'
import { serialize } from 'object-to-formdata'
import { mdiPlus, mdiClose } from '@mdi/js'
import hasPermission from '@/mixins/hasPermission'
import validationRules from '@/mixins/validationRules'

export default {
  mixins: [validationRules, hasPermission],
  data() {
    return {
      isLoading: true,
      stations: [],
      station_id: '',
      station_name: '',
      serviceChargeOptions: [
        {
          name: 'Fixed Amount',
          value: 0,
        },
        {
          name: 'Percentage',
          value: 1,
        },
      ],
      icons: {
        mdiPlus,
        mdiClose,
      },
      prepaid_service_chargeLabel: 'Service charge (in %)',
      postpaid_service_chargeLabel: 'Service charge (in Ksh)',
      form: new Form({
        prepaid_cost_per_unit: '',
        prepaid_service_charge_in: '',
        prepaid_service_charge: [
          {
            from: '',
            to: '',
            amount: '',
          },
        ],
        postpaid_cost_per_unit: '',
        postpaid_service_charge_in: '',
        postpaid_service_charge: [
          {
            from: '',
            to: '',
            amount: '',
          },
        ],
        meter_reading_on: '',
        tell_user_meter_disconnection_on: '',
        actual_meter_disconnection_on: '',
        bill_due_on: '',
        delay_meter_reading_sms: '',
        meter_reading_sms_delay_days: '',
        monthly_service_charge: '',
        send_connection_fee_bill_remainder_sms: '',
        days_before_sending_connection_fee_bill_remainder_sms: '',
        payment_notification_phone_number: '',
      }),
      activeTab: '',
      tabs: [],
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.getSettings()
  },
  methods: {
    ...mapActions(['setMeterReadingDate']),
    getSettings() {
      this.isLoading = true
      axios
        .get('settings')
        .then(response => {
          this.stations = response.data
          this.station_id = response.data[0].id
          this.station_name = response.data[0].name
          this.applySettings(response.data[0])
          this.isLoading = false
        })
        .catch(error => {
          console.log('🚀 ~ file: SystemSettings.vue:694 ~ getSettings ~ error:', error)
          this.isLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    applySettings(data) {
      const { settings, meter_charges } = data
      const settingMappings = {
        bill_due_on: 'bill_due_on',
        meter_reading_on: 'meter_reading_on',
        tell_user_meter_disconnection_on: 'tell_user_meter_disconnection_on',
        actual_meter_disconnection_on: 'actual_meter_disconnection_on',
        delay_meter_reading_sms: 'delay_meter_reading_sms',
        meter_reading_sms_delay_days: 'meter_reading_sms_delay_days',
        monthly_service_charge: 'monthly_service_charge',
        send_connection_fee_bill_remainder_sms: 'send_connection_fee_bill_remainder_sms',
        days_before_sending_connection_fee_bill_remainder_sms: 'days_before_sending_connection_fee_bill_remainder_sms',
        payment_notification_phone_number: 'payment_notification_phone_number',
      }

      settings.forEach(setting => {
        const { key } = setting
        const { value } = setting
        if (settingMappings[key]) {
          this.form[settingMappings[key]] = value
          if (settingMappings[key] === 'payment_notification_phone_number' && value === '0') {
            console.log('here')
            this.form.payment_notification_phone_number = ''
          }
        }
      })

      meter_charges.forEach(meterSetting => {
        if (meterSetting.for === 'prepay') {
          this.form.prepaid_cost_per_unit = meterSetting.cost_per_unit
          this.form.prepaid_service_charge_in = meterSetting.service_charge_in_percentage
          this.form.prepaid_service_charge = meterSetting.service_charges
        }
        if (meterSetting.for === 'post-pay') {
          this.form.postpaid_cost_per_unit = meterSetting.cost_per_unit
          this.form.postpaid_service_charge_in = meterSetting.service_charge_in_percentage
          this.form.postpaid_service_charge = meterSetting.service_charges
        }
      })

      this.postpaid_service_chargeOptionChange()
      this.prepaid_service_chargeOptionChange()
    },
    selectedStationChange() {
      const selectedStation = this.stations.find(station => station.id === this.station_id)
      this.station_name = selectedStation.name
      const selectedStationIndex = this.stations.findIndex(station => station.id === this.station_id)
      this.applySettings(this.stations[selectedStationIndex])
    },
    saveSettings() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .submit('post', `settings/${this.station_id}`, {
            // Transform form objects to FormData
            transformRequest: [function (data, headers) {
              const { prepaid_service_charge, postpaid_service_charge } = data
              delete data.prepaid_service_charge
              delete data.postpaid_service_charge
              const serializedData = serialize(data)
              serializedData.append('prepaid_service_charge', JSON.stringify(prepaid_service_charge))
              serializedData.append('postpaid_service_charge', JSON.stringify(postpaid_service_charge))

              return serializedData
            }],
          })
          .then(() => {
            this.$notification.success('Settings updated successfully')
            this.setMeterReadingDate(this.form.meter_reading_on)
          })
          .catch(error => {
            this.$notification.error(error.response.data.message)
          })
      }
    },
    serviceChargeOptionChange(formField) {
      const { prepaid_service_charge_in, postpaid_service_charge_in } = this.form

      if (prepaid_service_charge_in === 0) {
        this.prepaid_service_chargeLabel = 'Service charge (in Ksh)'
      } else {
        this.prepaid_service_chargeLabel = 'Service charge (in %)'
      }

      if (postpaid_service_charge_in === 0) {
        this.postpaid_service_chargeLabel = 'Service charge (in Ksh)'
      } else {
        this.postpaid_service_chargeLabel = 'Service charge (in %)'
      }
    },
    addServiceChargeInput(list) {
      if (list.length > 15) {
        this.$notification.error('You can only add a maximum of 5 price ranges')

        return
      }
      list.push({ from: '', to: '', amount: '' })
    },
    removeServiceChargeInput(list, index) {
      list.splice(index, 1)
    },
    addPrepaidServiceChargeInput() {
      this.addServiceChargeInput(this.form.prepaid_service_charge)
    },
    removePrepaidServiceChargeInput(index) {
      this.removeServiceChargeInput(this.form.prepaid_service_charge, index)
    },
    addPostpaidServiceChargeInput() {
      this.addServiceChargeInput(this.form.postpaid_service_charge)
    },
    removePostpaidServiceChargeInput(index) {
      this.removeServiceChargeInput(this.form.postpaid_service_charge, index)
    },
  },
}
</script>

<style scoped>
.number-input {
  width: 50px;
}
.number-input-2 {
  width: 60px;
}
.number-input-3 {
  width: 70px;
}
</style>
